import { Injectable, inject } from '@angular/core';
import {
  FleetApiActions,
  SetVariablesDataAction,
} from '@dashboard/dashboard-store/actions';
import { SystemDataErrorsService } from '@dashboard/services/system-data-errors.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';

@Injectable()
export class SystemEffects {
  private actions$ = inject(Actions);
  private SystemDataError = inject(SystemDataErrorsService);

  //this runs only once on init app when FleetApiActions.success() is dispatched
  IngestionErrorCheck$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FleetApiActions.success),
        map(() => {
          console.log('F:EET A{O SUCCESS');
          setTimeout(() => {
            this.SystemDataError.IngestionErrorChecker();
          }, 200);
        })
      );
    },
    { dispatch: false }
  );
  ///When ws message run the process to check if the vessel is offline
  RunSystemErrorCheck$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SetVariablesDataAction),
        map((action) => {
          if (action.source === 'WS') {
            setTimeout(() => {
              this.SystemDataError.IngestionErrorChecker();
            }, 100);
          }
        })
      );
    },
    { dispatch: false }
  );
}
