import { Injectable } from '@angular/core';
import { TSelectedApiVariable } from '@core/api/naviqore-data-api.service';

import { SystemTags } from '@dashboard/models';
import { TransformTagToId } from '@dashboard/tags-to-ids.transformer';

export type DataSeriesVariable = TSelectedApiVariable & {
  stateId: string;
} & ExtraPayloadProps;
type ApiSources = 'query_data' | 'custering_data';
type Source = {
  tag: SystemTags;
  stateId: string;
} & ExtraPayloadProps;
type ExtraPayloadProps = {
  extraPayloadProps?: any;
};

@Injectable({
  providedIn: 'root',
})
export class DataToApiService {
  private static SIDEBAR_DATA: Source[] = [
    {
      stateId: 'downsample_wind',
      tag: 'WindSpeedRelK',
    },
    {
      stateId: 'downsample_engine_speed',
      tag: 'ME_RPM',
    },
    {
      stateId: 'downsample_engine_power',
      tag: 'ME_Power',
    },
    {
      stateId: 'downsample_vessel_speed',
      tag: 'SOG',
    },
  ];

  private static VESSEL_INFO_DATA: {
    apiSource: ApiSources;
    sources: Source[];
  }[] = [
    {
      apiSource: 'query_data',
      sources: [
        {
          stateId: 'chart_ME_Power',
          tag: 'ME_Power',
        },
        {
          stateId: 'chart_ME_Power_Ref_HeavyProp',
          tag: 'ME_Power_Ref_HeavyProp',
        },
        {
          stateId: 'chart_ME_Power_Ref_LightProp',
          tag: 'ME_Power_Ref_LightProp',
        },
        {
          stateId: 'chart_speed_through_water',
          tag: 'STW',
        },
        {
          stateId: 'chart_speed_over_ground',
          tag: 'SOG',
        },
        {
          stateId: 'chart_ME_RPM',
          tag: 'ME_RPM',
        },
        {
          stateId: 'chart_Current',
          tag: 'Current',
        },
        {
          stateId: 'chart_WindSpeedAbsBF',
          tag: 'WindSpeedAbsBF',
        },
        {
          stateId: 'chart_Slip',
          tag: 'slip',
        },
        //
        {
          stateId: 'chart_ME_FOC_TPD',
          tag: 'ME_FOC_TPD',
        },
        {
          stateId: 'chart_DraftAft',
          tag: 'DraftAft',
        },
        {
          stateId: 'chart_DraftFor',
          tag: 'DraftFor',
        },
        {
          stateId: 'chart_DraftMidP',
          tag: 'DraftMidP',
        },
        {
          stateId: 'chart_DraftMidS',
          tag: 'DraftMidS',
        },
      ],
    },
    {
      apiSource: 'custering_data',
      sources: [
        {
          stateId: 'bar_windspeed_bf',
          tag: 'WindSpeedAbsBF',
        },
        {
          stateId: 'bar_engine_speed',
          tag: 'ME_RPM',
        },
        {
          stateId: 'bar_sog',
          tag: 'SOG',
        },
        {
          stateId: 'pie_steaming_time',
          tag: 'OperationalStatus',
          extraPayloadProps: {
            transformation: { type: 'FORMATTED' },
          },
        },
      ],
    },
  ];

  public SidebarQueryPayload(): DataSeriesVariable[] {
    return DataToApiService.SIDEBAR_DATA.map((data) => {
      return {
        tagId: TransformTagToId(data.tag),
        stateId: data.stateId,
      };
    });
  }
  public WindowInfoDataQueryPayload(): DataSeriesVariable[] {
    return DataToApiService.VESSEL_INFO_DATA.filter(
      (api) => api.apiSource === 'query_data'
    )
      .map((api) => api.sources)
      .flat()
      .map((data) => {
        return {
          tagId: TransformTagToId(data.tag),
          stateId: data.stateId,
        };
      });
  }
  public WindowInfoClusteringQueryPayload(): DataSeriesVariable[] {
    const d = DataToApiService.VESSEL_INFO_DATA.filter(
      (api) => api.apiSource === 'custering_data'
    )
      .map((api) => api.sources)
      .flat()
      .map((data) => {
        return {
          tagId: TransformTagToId(data.tag),
          stateId: data.stateId,
          ...data,
        };
      });
    return d;
  }
}
