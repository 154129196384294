import {
  AggregationStepDashboard,
  DMSState,
  IDashboardUiState,
  RightBar,
} from '@dashboard-store';
import { IStep } from '@dashboard/components/map-timeline/timeline.service';
import { TraceConfig } from '@dashboard/models';
import { createAction, props } from '@ngrx/store';

enum DashboardUitStateActions {
  INITIALIZE = '[DASHBOARD UI STATE] INITIALIZE',
  SET_SELECTED_VESSEL = '[DASHBOARD UI STATE] SET_SELECTED_VESSEL',
  SET_DATES = '[DASHBOARD UI STATE] SET_DATES',
  SET_VESSELS = '[DASHBOARD UI STATE] SET VESSELS',
  SET_SIDEBAR_EXPAND = '[DASHBOARD UI STATE] SET_SIDEBAR_EXPAND',
  SET_SIDEBAR_HEAD_EXPAND = '[DASHBOARD UI STATE] SET TOGGLE SIDEBAR EXPAND',
  SET_VESSEL_WINDOW_EXPAND = '[DASHBOARD UI STATE] SET_VESSEL_WINDOW_EXPAND',
  SET_FOCUS_MARKER_MAP = '[DASHBOARD UI STATE] SET_FOCUS_MARKER_MAP',
  SET_TRACE_CONFIG = '[DASHBOARD UI STATE] SET_TRACE_CONFIG',
  UPDATE_TRACE_CONFIG = '[DASHBOARD UI STATE] UPDATE_TRACE_CONFIG',
  SET_ACTIVE_TRACE = '[DASHBOARD UI STATE] SET_ACTIVE_TRACE',
  SET_TRACE_OFFSET = '[DASHBOARD UI STATE] SET_TRACE_OFFSET',
  EXTEND_TRACE_CONFIG = '[DASHBOARD UI STATE] EXTEND_TRACE_CONFIG',
  ADD_NEW_TRACE_VALUE = '[DASHBOARD UI STATE] ADD_NEW_TRACE_VALUE',
  TOGGLE_VESSEL_NAME_INFO = '[DASHBOARD UI STATE] TOGGLE_VESSEL_NAME_INFO',
  SET_REALTIME_FLAG = 'SET_REALTIME_FLAG',
  SET_RIGHTBAR = 'SET_RIGHTBAR',
  SET_MAP_DMS_CLICKS = 'SET_MAP_DMS_CLICKS',
  TOGGLE_MAP_DMS = 'TOGGLE_MAP_DMS',
  GET_WEATHER_DATA = 'GET_WEATHER_DATA',
  SET_AGGRAGATION_TYPE = 'SET_AGGRAGATION_TYPE',
  SET_AGGRAGATION_STEP = 'SET_AGGRAGATION_STEP',
  SET_OFFSET_STEP = 'SET_OFFSET_STEP',
  SET_TIMEZONE = 'SET_TIMEZONE',
}
const AppInitializeAction = createAction(DashboardUitStateActions.INITIALIZE);

const SetVesselsAction = createAction(
  DashboardUitStateActions.SET_VESSELS,
  props<{ vessels: IDashboardUiState['vessels'] }>()
);

const SetSelectedVesselAction = createAction(
  DashboardUitStateActions.SET_SELECTED_VESSEL,
  props<{ vesselId: string }>()
);

const SetSidebarExpandAction = createAction(
  DashboardUitStateActions.SET_SIDEBAR_EXPAND,
  props<{ isSidebarOpen: boolean }>()
);
const SetSidebarHeadExpandAction = createAction(
  DashboardUitStateActions.SET_SIDEBAR_HEAD_EXPAND
);

const SetVesselWindowAction = createAction(
  DashboardUitStateActions.SET_VESSEL_WINDOW_EXPAND
);
const SetSystemDatesAction = createAction(
  DashboardUitStateActions.SET_DATES,
  props<
    | { fromDate: string; toDate?: string }
    | { fromDate?: string; toDate: string }
  >()
);

const FocusMapMarkerAction = createAction(
  DashboardUitStateActions.SET_FOCUS_MARKER_MAP,
  props<{ vesselId: string }>()
);
const SetTraceConfigAction = createAction(
  DashboardUitStateActions.SET_TRACE_CONFIG,
  props<{ traceConfig: TraceConfig[] }>()
);

const UpdateActiveTrace = createAction(
  DashboardUitStateActions.UPDATE_TRACE_CONFIG,
  props<{ traceConfig: TraceConfig }>()
);

const SetActiveTraceAction = createAction(
  DashboardUitStateActions.SET_ACTIVE_TRACE,
  props<{ activeConfigId: number }>()
);

const SetOffsetStepAction = createAction(
  DashboardUitStateActions.SET_OFFSET_STEP,
  props<{ step: IStep }>()
);
const ExtendTraceRangeAction = createAction(
  DashboardUitStateActions.EXTEND_TRACE_CONFIG,
  props<{ configId: number; range: TraceConfig['range'] }>()
);

const AddNewTracePointAction = createAction(
  DashboardUitStateActions.ADD_NEW_TRACE_VALUE,
  props<{ position: string; selectedImo: string }>()
);

const ToggleVesselNameInfoAction = createAction(
  DashboardUitStateActions.TOGGLE_VESSEL_NAME_INFO
);

const SetRealtimeFlagAction = createAction(
  DashboardUitStateActions.SET_REALTIME_FLAG,
  props<{ enableRealtime: boolean }>()
);

const SetRightBar = createAction(
  DashboardUitStateActions.SET_RIGHTBAR,
  props<{ selected: RightBar }>()
);

const ToggleDmsAction = createAction(DashboardUitStateActions.TOGGLE_MAP_DMS);
const SetValueDmsMapClicksAction = createAction(
  DashboardUitStateActions.SET_MAP_DMS_CLICKS,
  props<DMSState>()
);

const SetAggregationTypeAction = createAction(
  DashboardUitStateActions.SET_AGGRAGATION_TYPE,
  props<{
    aggStepType: AggregationStepDashboard | null;
  }>()
);
const SetAggregationStepAction = createAction(
  DashboardUitStateActions.SET_AGGRAGATION_STEP,
  props<{
    aggStepSize: number | null;
  }>()
);

const SetTimezoneAction = createAction(
  DashboardUitStateActions.SET_TIMEZONE,
  props<{ timezone: number }>()
);

export {
  DashboardUitStateActions,
  SetSelectedVesselAction,
  SetVesselsAction,
  SetSidebarHeadExpandAction,
  SetSidebarExpandAction,
  SetTraceConfigAction,
  SetActiveTraceAction,
  ExtendTraceRangeAction,
  UpdateActiveTrace,
  AddNewTracePointAction,
  FocusMapMarkerAction,
  SetVesselWindowAction,
  ToggleVesselNameInfoAction,
  SetRealtimeFlagAction,
  SetRightBar,
  AppInitializeAction,
  ToggleDmsAction,
  SetValueDmsMapClicksAction,
  SetSystemDatesAction,
  SetAggregationStepAction,
  SetAggregationTypeAction,
  SetOffsetStepAction,
  SetTimezoneAction,
};
